import React from "react";
import PaneHeader from "IDE/Components/PaneHeader";
import styled from "styled-components";

const Container = styled.div`
  width: 250px;
`;

const WorkflowPane = () => {
  return (
    <Container>
      <PaneHeader title="Workflows" />
    </Container>
  );
};

export default WorkflowPane;
