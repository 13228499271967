import type { AppState } from "ee/reducers";
import { ZAvailableExtensions, type ZCustomEvent } from "ee/types/zuora";
import { useZuoraComponentPage } from "pages/AppIDE/hooks/useZuoraComponentPage";
import { useSelector } from "react-redux";

const CustomEventPage = () => {
  const customEventType = ZAvailableExtensions.CustomEvent;

  const selectedEvents: ZCustomEvent[] | null = useSelector(
    (state: AppState) => state.ui.zuoraComponents[customEventType].data,
  );
  const componentManagerIsLoaded: boolean = useSelector(
    (state: AppState) => state.ui.zuoraComponents.componentManagerIsLoaded,
  );

  const lastSaveAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customEventType].lastSaveAttemptSuccessful,
  );

  const lastLoadAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customEventType].lastLoadAttemptSuccessful,
  );

  const page = useZuoraComponentPage(
    customEventType,
    selectedEvents,
    componentManagerIsLoaded,
    lastSaveAttemptSuccessful,
    lastLoadAttemptSuccessful,
  );

  return page;
};

export default CustomEventPage;
