import type { AppState } from "ee/reducers";
import {
  ZAvailableExtensions,
  type ZCustomFieldDefinition,
} from "ee/types/zuora";
import { useZuoraComponentPage } from "pages/AppIDE/hooks/useZuoraComponentPage";
import { useSelector } from "react-redux";

const CustomFieldPage = () => {
  const customFieldType = ZAvailableExtensions.CustomField;

  const selectedCustomFields: ZCustomFieldDefinition[] | null = useSelector(
    (state: AppState) => state.ui.zuoraComponents[customFieldType].data,
  );

  const componentManagerIsLoaded: boolean = useSelector(
    (state: AppState) => state.ui.zuoraComponents.componentManagerIsLoaded,
  );

  const lastSaveAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customFieldType].lastSaveAttemptSuccessful,
  );

  const lastLoadAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customFieldType].lastLoadAttemptSuccessful,
  );

  const page = useZuoraComponentPage(
    customFieldType,
    selectedCustomFields,
    componentManagerIsLoaded,
    lastSaveAttemptSuccessful,
    lastLoadAttemptSuccessful,
  );

  return page;
};

export default CustomFieldPage;
