/* eslint-disable @typescript-eslint/no-restricted-imports */
import type { AppState } from "ce/reducers";
import {
  ZAvailableExtensions,
  type PersistentZObjectDefinition,
} from "ce/types/zuora";
import { useZuoraComponentPage } from "pages/AppIDE/hooks/useZuoraComponentPage";
import { useSelector } from "react-redux";

const CustomObjectPage = () => {
  const customObjectType = ZAvailableExtensions.CustomObject;

  const selectedObjects: PersistentZObjectDefinition[] | null = useSelector(
    (state: AppState) => state.ui.zuoraComponents[customObjectType].data,
  );
  const componentManagerIsLoaded: boolean = useSelector(
    (state: AppState) => state.ui.zuoraComponents.componentManagerIsLoaded,
  );

  const lastSaveAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customObjectType].lastSaveAttemptSuccessful,
  );

  const lastLoadAttemptSuccessful: boolean | null = useSelector(
    (state: AppState) =>
      state.ui.zuoraComponents[customObjectType].lastLoadAttemptSuccessful,
  );

  const page = useZuoraComponentPage(
    customObjectType,
    selectedObjects,
    componentManagerIsLoaded,
    lastSaveAttemptSuccessful,
    lastLoadAttemptSuccessful,
  );

  return page;
};

export default CustomObjectPage;
