import React from "react";
import PaneHeader from "IDE/Components/PaneHeader";
import styled from "styled-components";

const Container = styled.div`
  width: 250px;
`;

const CustomFieldPane = () => {
  return (
    <Container>
      <PaneHeader title="Custom Field" />
    </Container>
  );
};

export default CustomFieldPane;
